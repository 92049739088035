import axiosCSPublicInstance from "../../../axios/axiosCSPublicInstance";
import { store } from "../../../reduxStore";
import { SET_AUTH_META } from "../redux/authSlice";

const generateJWTToken = async () => {
  const userMeta = {
    username: process.env.REACT_APP_COCACOLA_USERNAME,
    password: process.env.REACT_APP_COCACOLA_PASSWORD,
  };

  try {
    let response = await axiosCSPublicInstance.post(`/authenticate`, userMeta);
    let jwtToken = response?.data?.jwtToken;
    if (jwtToken === "INVALID_CREDENTIALS") {
      console.log("Bad credentials");
      return null;
    }
    const dispatch = store.dispatch;
    dispatch(
      SET_AUTH_META({
        authMeta: { username: userMeta.username, id: 0, status: true },
        authToken: `${jwtToken}`,
      })
    );
    return jwtToken;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export default generateJWTToken;
