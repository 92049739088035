import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectId: "",
  externalUserId: "",
  questions: [],
  selectedOptions: [],
  selectedTrackConfig: {
    mood: "",
    genre: "",
  },
  trackMeta: {
    trackName: "",
    description: "",
    coverImage: "",
    fileName: "",
  },
  projectDurationInsec: 0.1,
  timelineSeekTime: null,
};

const projectMetaSlice = createSlice({
  name: "PROJECT",
  initialState,
  reducers: {
    SET_PROJECT_META: (state, action) => {
      let projectMetaObj = action.payload;
      for (const key in projectMetaObj) {
        if (Object.hasOwnProperty.call(projectMetaObj, key)) {
          const element = projectMetaObj[key];
          state[key] = element;
        }
      }
    },
    SET_SELECTED_OPTION: (state, action) => {
      let { questionId, optionId } = action.payload;
      let selectedOptionIndex = state.selectedOptions.findIndex(
        (option) => option.questionId === questionId
      );
      state.selectedOptions[selectedOptionIndex].optionId = optionId;
    },
    SET_SELECTED_TRACK_CONFIG: (state, action) => {
      let { label, value } = action.payload;
      state.selectedTrackConfig[label] = value;
    },
    RESET_PROJECT_META: () => initialState,
  },
});

export default projectMetaSlice.reducer;
export const {
  SET_PROJECT_META,
  SET_SELECTED_OPTION,
  SET_SELECTED_TRACK_CONFIG,
  RESET_PROJECT_META,
} = projectMetaSlice.actions;
