import React from "react";
import "./FallBackPage.css";
import { useConfig } from "../../../customHooks/useConfig";

const FallBackPage = () => {
  let { config } = useConfig();

  return (
    <div className="fall_back_container">
      {/* <img
        src={config?.assets?.fallback}
        className="fall_back_logo"
        alt="logo"
      /> */}
    </div>
  );
};

export default FallBackPage;
