const navigationConstants = {
  HOME: "/",
  LOGIN: "/login",
  BRAND_TAGS: "/brand-tags",
  MOOD_TAGS: "/mood-tags",
  GENRE_TAGS: "/genre-tags",
  QUESTION: "/question",
  QUESTION_ID: "/question/:question_id",
  TRACK: "/track",
  TRACK_BY_PROJECT_ID: "/track/:project_id",
  TRACKING_USER_REPORTS: "/usage",
};

export default navigationConstants;
