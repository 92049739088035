import FallBackPage from "./pages/fallBackPage/FallBackPage";
import navigationConstants from "./navigationConstants";
import ProtectedRoutes from "./components/protectedRoutes/ProtectedRoutes";
import { Route, Routes, useLocation } from "react-router-dom";
import { Suspense, lazy } from "react";
import { useDispatch } from "react-redux";
import { RESET_LOADING_STATUS } from "../modules/common/redux/loaderSlice";
import { RESET_NOTIFICATION } from "../modules/common/redux/notificationSlice";
import { useEffect } from "react";

const Home = lazy(() => import("../modules/home/pages/home/Home"));
const Login = lazy(() => import("../modules/auth/pages/login/Login"));
const BrandTagsPage = lazy(() =>
  import("../modules/workSpace/pages/BrandTagsPage/BrandTagsPage")
);
const GenreTagsPage = lazy(() =>
  import("../modules/workSpace/pages/GenreTagsPage/GenreTagsPage")
);
const MoodTagsPage = lazy(() =>
  import("../modules/workSpace/pages/MoodTagsPage/MoodTagsPage")
);
const QuestionsPage = lazy(() =>
  import("../modules/workSpace/pages/QuestionsPage/QuestionsPage")
);
const TrackPage = lazy(() =>
  import("../modules/workSpace/pages/TrackPage/TrackPage")
);
const UserTrakingReport = lazy(() =>
  import("../modules/workSpace/trackingReports/mainReport/mainReportPage")
);
const PageNotFound = lazy(() =>
  import("../routes/pages/PageNotFound/PageNotFound")
);

function RoutesWithAnimation() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("RESET");
    dispatch(RESET_LOADING_STATUS());
    dispatch(RESET_NOTIFICATION());
  }, []);

  return (
    <Routes location={location} key={location.key}>
      <Route
        path={navigationConstants.HOME}
        element={
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        }
      />
      <Route
        path={navigationConstants.LOGIN}
        element={
          <Suspense fallback={<FallBackPage />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path={navigationConstants.QUESTION_ID}
        element={
          <ProtectedRoutes>
            <QuestionsPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path={navigationConstants.MOOD_TAGS}
        element={
          <ProtectedRoutes>
            <MoodTagsPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path={navigationConstants.GENRE_TAGS}
        element={
          <ProtectedRoutes>
            <GenreTagsPage />
          </ProtectedRoutes>
        }
      />
      {/* <Route
        path={navigationConstants.TRACK}
        element={
          <ProtectedRoutes>
            <TrackPage />
          </ProtectedRoutes>
        }
      /> */}
      <Route
        path={navigationConstants.TRACK_BY_PROJECT_ID}
        element={
          <ProtectedRoutes>
            <TrackPage />
          </ProtectedRoutes>
        }
      />
        <Route
        path={navigationConstants.TRACKING_USER_REPORTS}
        element={
          <ProtectedRoutes>
            <UserTrakingReport />
          </ProtectedRoutes>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesWithAnimation;
