import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import navigationConstants from "../../navigationConstants";
import FallBackPage from "../../pages/fallBackPage/FallBackPage";
import { Suspense, useEffect } from "react";
import { RESET_AUTH_STATE } from "../../../modules/auth/redux/authSlice";
import getCurrentUser from "../../../modules/auth/services/getCurrentUser";
import generateJWTToken from "../../../modules/auth/services/generateJWTToken";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);

  const checkIfTokenExpired = () => {
    getCurrentUser({
      onSuccess: () => {
        console.log("User is authenticated");
      },
      onError: () => {
        console.log("User is not authenticated");
        generateJWTToken();
      },
    });
  };

  useEffect(() => {
    if (authToken) {
      checkIfTokenExpired();
    } else {
      console.log("No token found, generating new token");
      generateJWTToken();
    }
  }, [authToken]);

  if (authToken) {
    return <Suspense fallback={<FallBackPage />}>{children}</Suspense>;
  } else {
    return <FallBackPage />;
  }
};
export default ProtectedRoutes;
