import axiosCSPrivateInstance from "../../../axios/axiosCSPrivateInstance";
import showNotification from "../../common/helperFunctions/showNotification";

const getCurrentUser = ({ onSuccess, onError }) => {
  axiosCSPrivateInstance
    .get(`/current-user`)
    .then(async (response) => {
      onSuccess?.(response);
    })
    .catch(() => {
      // showNotification("ERROR", "Something went wrong!");
      onError?.();
    });
};

export default getCurrentUser;
